import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './download.module.css'
import latestVersion from '../../static/latestversion.json'

function WindowsIcon() {
  // https://materialdesignicons.com/icon/microsoft-windows
  return (
    <svg className={styles.osIcon} viewBox={'0 0 24 24'}>
      <path
        fill="currentColor"
        d="M3,12V6.75L9,5.43V11.91L3,12M20,3V11.75L10,11.9V5.21L20,3M3,13L9,13.09V19.9L3,18.75V13M20,13.25V22L10,20.09V13.1L20,13.25Z"
      />
    </svg>
  )
}

function LinuxIcon() {
  // https://materialdesignicons.com/icon/linux
  return (
    <svg className={styles.osIcon} viewBox={'0 0 24 24'}>
      <path
        fill="currentColor"
        d="M14.62,8.35C14.2,8.63 12.87,9.39 12.67,9.54C12.28,9.85 11.92,9.83 11.53,9.53C11.33,9.37 10,8.61 9.58,8.34C9.1,8.03 9.13,7.64 9.66,7.42C11.3,6.73 12.94,6.78 14.57,7.45C15.06,7.66 15.08,8.05 14.62,8.35M21.84,15.63C20.91,13.54 19.64,11.64 18,9.97C17.47,9.42 17.14,8.8 16.94,8.09C16.84,7.76 16.77,7.42 16.7,7.08C16.5,6.2 16.41,5.3 16,4.47C15.27,2.89 14,2.07 12.16,2C10.35,2.05 9,2.81 8.21,4.4C8,4.83 7.85,5.28 7.75,5.74C7.58,6.5 7.43,7.29 7.25,8.06C7.1,8.71 6.8,9.27 6.29,9.77C4.68,11.34 3.39,13.14 2.41,15.12C2.27,15.41 2.13,15.7 2.04,16C1.85,16.66 2.33,17.12 3.03,16.96C3.47,16.87 3.91,16.78 4.33,16.65C4.74,16.5 4.9,16.6 5,17C5.65,19.15 7.07,20.66 9.24,21.5C13.36,23.06 18.17,20.84 19.21,16.92C19.28,16.65 19.38,16.55 19.68,16.65C20.14,16.79 20.61,16.89 21.08,17C21.57,17.09 21.93,16.84 22,16.36C22.03,16.1 21.94,15.87 21.84,15.63"
      />
    </svg>
  )
}

function AppleIcon() {
  // https://materialdesignicons.com/icon/apple
  return (
    <svg className={styles.osIcon} viewBox={'0 0 24 24'}>
      <path
        fill="currentColor"
        d="M18.71,19.5C17.88,20.74 17,21.95 15.66,21.97C14.32,22 13.89,21.18 12.37,21.18C10.84,21.18 10.37,21.95 9.1,22C7.79,22.05 6.8,20.68 5.96,19.47C4.25,17 2.94,12.45 4.7,9.39C5.57,7.87 7.13,6.91 8.82,6.88C10.1,6.86 11.32,7.75 12.11,7.75C12.89,7.75 14.37,6.68 15.92,6.84C16.57,6.87 18.39,7.1 19.56,8.82C19.47,8.88 17.39,10.1 17.41,12.63C17.44,15.65 20.06,16.66 20.09,16.67C20.06,16.74 19.67,18.11 18.71,19.5M13,3.5C13.73,2.67 14.94,2.04 15.94,2C16.07,3.17 15.6,4.35 14.9,5.19C14.21,6.04 13.07,6.7 11.95,6.61C11.8,5.46 12.36,4.26 13,3.5Z"
      />
    </svg>
  )
}

function AndroidIcon() {
  // https://materialdesignicons.com/icon/android
  return (
    <svg className={styles.osIcon} viewBox={'0 0 24 24'}>
      <path
        fill="currentColor"
        d="M16.61 15.15C16.15 15.15 15.77 14.78 15.77 14.32S16.15 13.5 16.61 13.5H16.61C17.07 13.5 17.45 13.86 17.45 14.32C17.45 14.78 17.07 15.15 16.61 15.15M7.41 15.15C6.95 15.15 6.57 14.78 6.57 14.32C6.57 13.86 6.95 13.5 7.41 13.5H7.41C7.87 13.5 8.24 13.86 8.24 14.32C8.24 14.78 7.87 15.15 7.41 15.15M16.91 10.14L18.58 7.26C18.67 7.09 18.61 6.88 18.45 6.79C18.28 6.69 18.07 6.75 18 6.92L16.29 9.83C14.95 9.22 13.5 8.9 12 8.91C10.47 8.91 9 9.24 7.73 9.82L6.04 6.91C5.95 6.74 5.74 6.68 5.57 6.78C5.4 6.87 5.35 7.08 5.44 7.25L7.1 10.13C4.25 11.69 2.29 14.58 2 18H22C21.72 14.59 19.77 11.7 16.91 10.14H16.91Z"
      />
    </svg>
  )
}

export default function DownloadPage() {
  // TODO: calculate how many "days ago" was the latestVersion published
  // const [maj, min, rest] = latestVersion.split('.')
  // const versionDay = parseInt(rest.split('-')[0], 10)
  // const versionYear = 2000 + parseInt(maj === '0' ? min.substring(0, 2) : maj, 10)
  // const versionMonth = parseInt(
  //   maj === '0' ? min.substring(2, 4) : min.substring(0, 2),
  //   10
  // );

  const linuxDebURL = `https://github.com/staltz/manyverse/releases/download/v${latestVersion}/manyverse_${latestVersion}_amd64.deb`
  const linuxTarURL = `https://github.com/staltz/manyverse/releases/download/v${latestVersion}/manyverse-${latestVersion}.tar.gz`
  const winExeURL = `https://github.com/staltz/manyverse/releases/download/v${latestVersion}/manyverse-${latestVersion}-windows-x64-nsis-installer.exe`
  const macDmgURL = `https://github.com/staltz/manyverse/releases/download/v${latestVersion}/Manyverse-${latestVersion}.dmg`
  const androidApkURL = `https://github.com/staltz/manyverse/releases/download/v${latestVersion}/manyverse-${latestVersion}.apk`

  const changelogURL =
    `https://gitlab.com/staltz/manyverse/blob/master/CHANGELOG.md#` +
    latestVersion.replace(/\./g, '')

  const testVersion = "0.2407.28-beta"
  const testLinuxDebURL = `https://github.com/staltz/manyverse/releases/download/v${testVersion}/manyverse_${testVersion}_amd64.deb`
  const testLinuxTarURL = `https://github.com/staltz/manyverse/releases/download/v${testVersion}/manyverse-${testVersion}.tar.gz`

  return (
    <>
      <Layout>
        <SEO title="Download" />
        <h1 className={styles.title}>Download</h1>
        <p style={{textAlign: 'center', marginBottom: '2em'}}>
          The latest version is <b>{latestVersion}</b>. Read the{' '}
          <a href={changelogURL}>release notes here</a>.
        </p>
        <p style={{textAlign: 'center', marginBottom: '2em'}}>
          The special release talked about in <a href="/blog/2024-07-30">this blogpost</a> can be downloaded for linux <a href={testLinuxDebURL}>deb</a> and <a href={testLinuxTarURL}>tar</a>.
        </p>
        <div className={styles.options}>
          <div className={styles.optionCard}>
            <AndroidIcon />
            <h1>Android</h1>
            <a
              href="https://play.google.com/store/apps/details?id=se.manyver"
              className={styles.button}
            >
              Google Play
            </a>
            <a
              href="https://f-droid.org/app/se.manyver"
              className={styles.button}
            >
              F-Droid
            </a>
            <a href={androidApkURL} className={styles.button}>
              Download apk
            </a>
          </div>

          <div className={styles.optionCard}>
            <AppleIcon />
            <h1>iOS</h1>
            <a
              href="https://apps.apple.com/app/manyverse/id1492321617"
              className={styles.button}
            >
              App Store
            </a>
          </div>

          <div className={styles.optionCard}>
            <WindowsIcon />
            <h1>Windows</h1>
            <a href={winExeURL} className={styles.button}>
              Download exe
            </a>
          </div>

          <div className={styles.optionCard}>
            <AppleIcon />
            <h1>macOS</h1>
            <a href={macDmgURL} className={styles.button}>
              Download dmg
            </a>
          </div>

          <div className={styles.optionCard}>
            <LinuxIcon />
            <h1>Linux</h1>
            <a href={linuxDebURL} className={styles.button}>
              Download deb
            </a>
            <a href={linuxTarURL} className={styles.button}>
              Download tar.gz
            </a>
            <a
              href="https://flathub.org/apps/details/se.manyver.Manyverse"
              className={styles.button}
            >
              Flathub
            </a>
          </div>
        </div>
        <div style={{marginTop: '6em'}} />
      </Layout>
    </>
  )
}
